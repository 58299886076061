import React from 'react'
import { BannerContainer } from './styles'
import BannerImageDesktop from '../../images/Tristreamcapitialbanner.jpg'
import BannerImageMobile from '../../images/TristreamcapitialBannerMobile.jpg'

function Banner() {
    return (
        <BannerContainer>
            <img src={ BannerImageDesktop } className="image-desktop" />
            <img src={ BannerImageMobile } className="image-mobile" />
        </BannerContainer>
    )
}

export default Banner
