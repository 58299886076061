import React from 'react'
import { HeaderContainer } from './styles'
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram } from "react-icons/fa6";

function Header() {
    return (
        <HeaderContainer>
            <div className="logo">
                <a href='https://tristreamcapital.com/'>
                    <img src='TristreamLogo.png' />
                </a>
            </div>
            <div className="header-section2">
                <div className="social-share">
                    {/* <FaFacebook /> */}
                    <a href='https://twitter.com/TristreamCap' target='_blank'>
                        <FaTwitter />
                    </a>
                    <a href='https://linkedin.com/company/tristream-capital' target='_blank'>
                        <FaLinkedin />
                    </a>
                    {/* <FaInstagram /> */}
                </div>
                <div className="contact-us">
                    <button className="glow">
                        Contact Us
                    </button>
                </div>
            </div>
        </HeaderContainer>
    )
}

export default Header
