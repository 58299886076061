import React from 'react'
import CardInfo from './CardInfo'
import ValueCard from './ValueCard'

function Whoweare() {
    const data=[
        {
            title: 'OUR VALUE PROPOSITION',
            info: [
                'We aim to provide alternative financing to mid-tier resources sector',
                'We use stream and royalty technology to achieve our objectives',
                'We focus on cashflow generation and target investment IRR of 15% pa +',
                'Use our unique skill set of structured finance, engineering and geology to provide bespoke solutions, solve the mid-tier resource co funding gap and create ALPHA for our investors',
                'Multi jurisdictional team'
            ]
        },
        {
            title: 'WHO DO WE FINANCE',
            info: [
                'Tier 1 assets across jurisdictions',
                'Producing, near producing , focus on precious and critical metals, acquisition finance, cost overrun facilities, mezzanine etc ',
                'Deals sourced primarily through our OWN network ……all private and unique opportunities. We focus on very different segment that the typical TSXV royalty cos.',
                'Tristream current pipeline of deals in excess of USD 300 Million',
            ]
        },
        {
            title: 'WHY SHOULD INVESTORS CONSIDER',
            info: [
                'We provide direct commodity exposure',
                'Focus on cashflow generation and dividend payments',
                'Focus on revenue maximisation with a view to generating quick valuation growth.',
                'Diversification play – we focus on Latam, Asia Pac, Africa',
            ]
        },
        {
            title: 'OUR BACKGROUNDS',
            info: [
                'Tier 1 investment banks – Morgan Stanley,  Merrill, Macquarie',
                'Strong operational experience, deal closers',
                'Strong educational background including ESG qualifications',
            ]
        },
    ]

    const valueInfo =  [
        {
            info: 'We aim to provide alternative financing to mid-tier resources sector',
            icon: 'financingvalue.svg'
        },
        {
            info: 'We use stream and royalty technology to achieve our objectives',
            icon: 'royaltyvalue.svg'
        },
        {
            info: 'We focus on prioritizing the cash flow and double-digit returns.',
            icon: 'cashflowvalue.svg'
        },
        {
            info: 'Expertise in finance, engineering, and geology to enhance investor returns',
            icon: 'expertisevalue.svg'
        },
        {
            info: 'An Experienced multi-jurisdictional team with global expertise.',
            icon: 'teamvalue.svg'
        },
    ]
    return (
        <div className="whoweare">
            <div className="color-title">
                <h2 className="first">Our</h2>
                <h2 className="second">Value Proposition</h2>
            </div>
            <div className="cardinfo-container">
                {
                    valueInfo.map((item) => {
                        return (
                            <ValueCard
                                valueInfo={ item }
                            />
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Whoweare
