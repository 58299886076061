import React from 'react'

function Section2() {
    return (
        <div className="section2-container">
            <div className="section2-part1">
                <div className="color-title">
                    <h2 className="first">Why</h2>
                    <h2 className="second">Tristream Capital</h2>
                </div>
                <p>{'Our Mission: Maximizing Shareholder Value with a focus on cashflow generation & high risk adjusted returns'}</p>
            </div>
            <div className="section2-part2">
                <div className="section2-image">
                    <img src='Tristreamdesktop2.png' />
                </div>
                <div className="section2-bulletin">
                    <div className="bullet-points">
                        <div className="icon-title">
                            <img src='portfolio.svg' />
                            <p>Portfolio</p>
                        </div>
                        <p>To consist of a good mix of both stable cashflow generating and longer-term streams and royalties providing direct exposure to Precious, Energy Transition and Industrial Metals</p>
                    </div>
                    <div className="bullet-points">
                        <div className="icon-title">
                            <img src='invest.svg' />
                            <p>Investment Focus</p>
                        </div>
                        <p>Tier 1 assets across all jurisdictions that are producing or near producing.</p>
                    </div>
                    <div className="bullet-points">
                        <div className="icon-title">
                            <img src='expertise.svg' />
                            <p>Expertise & Experience</p>
                        </div>
                        <p>We will seek to leverage on the deep industry expertise and experience of its senior management team in metals & mining origination, financing and operating companies (combined experience greater than 75 years)</p>
                    </div>
                    <div className="bullet-points">
                        <div className="icon-title">
                            <img src='revenue.svg' />
                            <p>Revenue Growth & Diversification</p>
                        </div>
                        <p>By providing alternative financing solutions to the mid tier resources sector exclusively originated from our Team’s wide network.</p>
                    </div>
                    <div className="bullet-points">
                        <div className="icon-title">
                            <img src='strategy.svg' />
                            <p>Strategic Growth</p>
                        </div>
                        <p>Via establishment of a resource sector focused private credit fund in order to provide financing solutions across the entire capital structure targeted towards the mid tier companies.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Section2
