import React from 'react'
import TeamMember3 from './TeamMember3'
import TeamMember4 from './TeamMember4'
import TeamMemberList from './TeamMemberList'

function TeamSection() {
    return (
        <div className="team-section">
            <div className="color-title">
                <h2 className="first">{'TriStream Capital'}</h2>
                <h2 className="second">{'Team'}</h2>
            </div>
            <p>{'Key Executives with Global Finance & Mining Experience'}</p>
            <TeamMemberList />
            {/* <TeamMember4 /> */}
        </div>
    )
}

export default TeamSection
