import React from 'react'
import Header from '../Header'
import Banner from './Banner'
import Divider from './Divider'
import Section1 from './Section1'
import Section2 from './Section2'
import HeadlineImage from './HeadlineImage'
import { HomepageContainer } from './styles'
import TeamMemberList from './TeamMemberList'
import TeamSection from './TeamSection'
import Footer from './Footer'
import Whoweare from '../Whoweare'

function HomePage() {
    return (
        <HomepageContainer>
            <Header />
            <Banner />
            <Whoweare />
            {/* <Divider /> */}
            {/* <Section1 /> */}
            {/* <Divider /> */}
            <Section2 />
            <HeadlineImage
                title1={'Current'}
                title2={'Investment Pipeline'}
                image={ 'Tristreamcappipeline.jpg' }
                content={
                    <ul>
                        <li>Mexico (Gold & Silver)</li>
                        <li>Central Asia (Zinc/Lead/Silver)</li>
                        <li>Peru (Gold)*</li>
                        <li>Argentina (Lithium)</li>
                        <li>APAC(Heavy Mineral Sands + REE)</li>
                    </ul>
                }
            />
            <Divider />
            <TeamSection />
            {/* <Divider /> */}
            <Footer />
        </HomepageContainer>
    )
}

export default HomePage
