import React from 'react'
import { DividerContainer } from './styles'

function Divider() {
    return (
        <DividerContainer><div class="astrodividermask"></div><span><i>&#8693;</i></span></DividerContainer>
    )
}

export default Divider
