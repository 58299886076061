import React from 'react'

function ValueCard({ valueInfo }) {
    return (
        <div className="value-proposition-card">
            <img src={ valueInfo.icon } />
            <p>{ valueInfo.info }</p>
        </div>
    )
}

export default ValueCard
