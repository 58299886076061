import React, { useState } from 'react'
import PopoverArea from './PopoverArea'
import { PopOverContainer } from './styles'
import TeamMember from './TeamMember'

function TeamMemberList() {
    const members = [
        {
            name: 'Mark Thompson',
            role: 'Non Executive Chairman',
            image: 'https://www.saltedstone.com/hubfs/steveA500-1.jpg',
            background: [
                'Mark is a highly experienced trader in metal derivatives and physical commodities, mining investor and entrepreneur.',
                'He has founded (and listed) several mineral exploration companies in the natural resources sector and he has also acted as an expert witness in high-profile metals and mining related commercial dispute.',
                'He is the former Chief Investment Officer and co-founder of Galena Asset Management Ltd, the fund management arm of Trafigura, and then latterly a partner at Apollo Management, one of the world’s largest alternative asset managers.',
                'Academic Qualifications: He holds an B.A. in Physics from Oxford University, is married with two children and lives just outside of London.'
            ],
            indusExp: {
                present: [
                    'Sinton Spence Chartered Accountants',
                    'Adyton Resources'
                ],
                past: [
                    'Sierra Mining',
                    'Shell Oil Exploration and Production PNG Ltd'
                ]
            },
            left: '13rem',
            top: '226rem',
            linkedIn: 'https://linkedin.com/'
        },
        {
            name: 'Prad Mazumder',
            role: 'Managing Partner - CIO',
            image: 'https://www.saltedstone.com/hubfs/steveA500-1.jpg',
            background: [
                'Investment banking professional with more than 25 years experience in the Resources Sector',
                'Former Asia Head of Commodities Origination & Structuring at Morgan Stanley, Deutsche Bank having started his career with Merrill Lynch.',
                'Successfully deployed in excess of US$3.5 Billion in Asia Pacific middle market resources space with full capital preservation.',
                'Strong deal origination and structuring track record having closed multiple award- winning Mining financing transactions.',
                'Currently focused on developing ESG integrated debt, hybrid debt and royalty financing solutions for middle market mining companies involved in transition metals.',
                'Academic Qualifications: B Tech(Chemical Engineering) , MBA(Finance), CFA in ESG Investing'
            ],
            indusExp: {
                past: [
                    'Morgan Stanley (Head, Commodities Financing)',
                    'Deutsche Bank (Head, Structured Commodities)',
                    'Merrill Lynch (VP, Commodities Structuring)'
                ]
            },
            left: '41rem',
            top: '200rem',
            linkedIn: 'https://linkedin.com/in/prad-mazumder-a4370a2b5/'
        },
        {
            name: 'Chad Lewis',
            role: 'Managing Partner - COO',
            image: 'https://www.saltedstone.com/hubfs/steveA500-1.jpg',
            background: [
                'Over 15 years working in financial markets',
                'Former executive at Macquarie Group for several years. Working predominantly in Hedge Funds, Private Equity and Structured Products for Institutional and Family Office clients. Roles included risk, reporting and relationship management in the Australian, Asian, European and US markets.',
                'Derivatives trader Developing quantitative and qualitative trading strategies across Equities, Commodities, Fixed Income and FX, globally.',
                'Most recently a Partner at APC, a private equity firm focussed on the natural resources sector with investments in Latin America and Africa.'
            ],
            indusExp: {
                past: [
                    'APC, Partner',
                    'Private Gold Mining Company, Director',
                    'Macquarie Group (Funds Management, Structured Products)',
                    'Derivatives Trader (London and Sydney)',
                ]
            },
            right: '18rem',
            top: '220rem',
            linkedIn: 'https://linkedin.com/in/chad-l-722a4a10/'
        },
        {
            name: 'Marcio Fonseca',
            role: 'Consultant, Chief Technical Advisor',
            image: 'https://www.saltedstone.com/hubfs/steveA500-1.jpg',
            background: [
                'Seasoned mining and financial executive with more than 30 years experience in the Resources sector.',
                'Strong entrepreneurial skills and extensive experience in corporate strategy',
                'Successful track record of identifying undervalued mining investment opportunities with extensive experience in a all stages of mining projects development. Extensive experience related to adding value to resource assets from exploration to advancing towards development stage and successful mining transaction. Previous direct involvement in world-class resource discoveries.',
                'Former Division Director at Macquarie Bank in New York and Vancouver.',
                'Academic Qualifications: BA Geology, Economic Engineering, MBA (Mineral Economics)',
            ],
            indusExp: {
                past: [
                    'Goodman Hardie',
                    'Apollo Gas',
                ]
            },
            left: '4rem',
            top: '272rem',
            linkedIn: 'https://linkedin.com/'
        },
        // {
        //     name: 'Paul Welch',
        //     role: 'Director',
        //     image: 'https://www.saltedstone.com/hubfs/steveA500-1.jpg',
        //     background: [
        //         'Over 15 years working in financial markets',
        //         'Former executive at Macquarie Group for several years. Working predominantly in Hedge Funds, Private Equity and Structured Products for Institutional and Family Office clients. Roles included risk, reporting and relationship management in the Australian, Asian, European and US markets.',
        //         'Derivatives trader Developing quantitative and qualitative trading strategies across Equities, Commodities, Fixed Income and FX, globally.',
        //         'Most recently a Partner at APC, a private equity firm focussed on the natural resources sector with investments in Latin America and Africa.'
        //     ],
        //     indusExp: {
        //         present: [
        //             'Sinton Spence Chartered Accountants',
        //             'Adyton Resources'
        //         ],
        //         past: [
        //             'Sierra Mining',
        //             'Shell Oil Exploration and Production PNG Ltd'
        //         ]
        //     },
        //     left: '42rem',
        //     top: '262rem',
        //     linkedIn: 'https://linkedin.com/'
        // }
    ]
    return (
        <div className="team-members-list">
            <div className="member-list-1">
                {
                    members.map((item, i) => {
                        return (
                                <>
                                    <TeamMember
                                        name={ item.name }
                                        role={ item.role }
                                        image={ item.image }
                                        background={ item.background }
                                        indusExp={ item.indusExp }
                                        memberKey={ item.name }
                                        left={ item.left }
                                        right={ item.right }
                                        linkedIn={ item.linkedIn }
                                    />
                                </>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default TeamMemberList
