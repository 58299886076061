import React from 'react'

function HeadlineImage({ title1, title2, image, content, direction }) {
    return (
        <div className="section3-container">
            <div className="section3-part1">
                <div className="color-title">
                    <h2 className="first">{ title1 }</h2>
                    <h2 className="second">{ title2 }</h2>
                </div>
                <div className="sub-section1" style={
                direction && direction === 'reverse' ? {
                    flexDirection: 'row-reverse'
                } : null
                }>
                    {/* { content } */}
                    <img src={ image } />
                </div>
            </div>
        </div>
    )
}

export default HeadlineImage
