import { Popover, Typography } from '@mui/material';
// import { makeStyles } from '@mui/system';
import React, { useState }  from 'react'
import LetteredAvatar from 'react-lettered-avatar';
import PopoverArea from './PopoverArea'
import { PopOverContainer, TeamMemberContainer } from './styles'

// const useStyles = makeStyles({
//     paper: {
//       backgroundColor: "pink !important",
//       color: "red"
//     }
// });

function TeamMember({ name, role, image, background, indusExp, memberKey, left, right, linkedIn }) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    // const classes = useStyles();
    return (
            <TeamMemberContainer className="teamcontainer" disableGutters>
                <div className="team-thumb-bg">
                    <div className="square team-thumb">
                        <div className="inner">
                            <a href={ linkedIn } target='_blank'>
                                <LetteredAvatar
                                    name={ name }
                                    size={ 120 }
                                    backgroundColor="#b98850"
                                />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="team-info">
                    <Typography
                        aria-owns={open ? 'mouse-over-popover' : undefined}
                        aria-haspopup="true"
                        onMouseEnter={handlePopoverOpen}
                        onMouseLeave={handlePopoverClose}
                        variant='h3'
                        className='name'
                    >
                        { name }
                    </Typography>
                    <span className="job">{ role }</span>
                    <Popover
                    id="mouse-over-popover"
                    sx={{
                        pointerEvents: 'none',
                        overflowY: 'scroll',
                    }}
                    disableAutoFocus
                    disableEnforceFocus
                    open={open}
                    elevation={ 10 }
                    anchorEl={anchorEl}
                    anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                    }}
                    transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                    }}
                    onClose={handlePopoverClose}
                    // disableRestoreFocus
                    className="popover-comp"
                >
                    <PopoverArea
                        background={ background }
                        indusExp={ indusExp }
                        name={ name }
                    />
                </Popover>
                </div>
            </TeamMemberContainer>
    )
}

export default TeamMember
