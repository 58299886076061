import styled from "styled-components";

export const CardInfoContainer = styled.div`
    display: flex;
    max-width: 45%;
    flex: 1 0 45%;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 2px 6px 3px #bec2e3;
    margin-bottom: 2rem;
    & .header-container {
        display: flex;
        width: 60%;
        align-items: center;
        justify-content: center;
        margin-top: 2rem;
        & h3 {
            font-family: "Alexandria", sans-serif;
            font-optical-sizing: auto;
            font-weight: 500;
            font-size: 16px;
            letter-spacing:1px;
            text-decoration: underline;
        }
        img {
            width: 3rem;
        }
    }
    & .body-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 2rem;
        & ul {
            & .item {
                font-family: "Alexandria", sans-serif;
                font-optical-sizing: auto;
                font-size: 14px;
                line-height: 1.4rem;
                letter-spacing: 1.6px;
                margin-bottom: 0.9rem;
                text-align: justify;
            }
        }
    }
`;