import { Container } from "@mui/material";
import styled from "styled-components";

export const HomepageContainer = styled.div`
    & .whoweare {
        @media (min-width:320px) and (max-width:479px)  { /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
            margin-top: 1rem;
            .color-title {
                flex-direction: column;
                & .first, .second {
                    margin: 0 !important;
                    display: flex;
                    font-size: 24px !important;
                    justify-content: center;
                }
            }
            .cardinfo-container {
                flex-direction: column;
                margin-top: 1rem;
                padding-top: 1rem !important;
                .value-proposition-card {
                    max-width: unset !important;
                    margin-block: 1rem !important;
                    & p {
                        font-size: 18px !important;
                    }
                }
            }
        }
        & .color-title {
            display: flex;
            justify-content: center;
            h2 {
                font-family: "Alexandria", sans-serif;
                font-optical-sizing: auto;
                font-size: 36px;
                margin-bottom: 2rem;
            }
            & .first {
                color: #4b4d81;
                margin-right: 0.5rem;
            }
            & .second {
                color: #b98850;
            }
        }
        display: flex;
        flex-direction: column;
        align-items: center;
        & .cardinfo-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
            padding: 3rem;
            background: #4b4d81;
            & .value-proposition-card {
                display: flex;
                max-width: 30%;
                flex: 1 0 30%;
                flex-direction: column;
                align-items: center;
                margin-block: 2rem;
                & img {
                    width: 25%;
                }
                & p {
                    font-family: "Alexandria", sans-serif;
                    font-optical-sizing: auto;
                    font-weight: 300;
                    font-size: 24px;
                    text-align: center;
                    color: white;
                }
            }
        }
    }
    & .footer {
        background: #4b4d81;
    }
    & .footer-container {
        margin-top: 8rem;
        h2 {
            font-family: "Alexandria", sans-serif;
            font-optical-sizing: auto;
            font-weight: 700;
            font-size: 24px;
            color: white !important;
        }
        p {
            font-family: "Alexandria", cursive;
            font-weight: 400;
            font-style: normal;
            color: white !important;
        }
        ul {
            li {
                font-family: "Alexandria", cursive;
                font-weight: 400;
                font-style: normal;
                color: white !important;
            }
        }
        a {
            color: #fff;
            text-decoration: none;
          }
          .pg-footer {
            font-family: 'Roboto', sans-serif;
          }
          
          
          .footer > .footer-copyright {
              background: linear-gradient(
                to right,
                #ffffff,
                #d0e9f8,
                #e1f4ff
              );
              background-size: 200% 100%; /* Double the width to accommodate the animation */
              animation: animateGradient 5s linear infinite;
              @keyframes animateGradient {
                  0% {
                    background-position: 0 50%; /* Start from left */
                  }
                  50% {
                    background-position: 100% 0%; /* Move to right */
                  }
                  100% {
                    background-position: 0 100%; /* Move back to left */
                  }
              
              }
              color: #fff;
          }
          .footer-wave-svg {
              background-color: transparent;
              display: block;
              height: 30px;
              position: relative;
              top: -1px;
              width: 100%;
          }
          .footer-wave-path {
              fill: white;
          }
          
          .footer-content {
              margin-left: auto;
              margin-right: auto;
              max-width: 1230px;
              padding: 60px 15px 90x;
              position: relative;
              display: flex;
              justify-content: space-between;
          }
          
          .footer-content-column {
              box-sizing: border-box;
              float: left;
              padding-left: 15px;
              padding-right: 15px;
              width: 100%;
              color: #fff;
              & .footer-logo {
                  width: 70%;
                  & a {
                      & img {
                          width: 100%;
                      }
                  }
              }
          }
          
          .footer-content-column ul li a {
            color: #fff;
            text-decoration: none;
          }
          .footer-content-column ul li a:hover {
            color: #fff;
            text-decoration: underline;
          }
          
          .footer-logo-link {
              display: inline-block;
          }
          .footer-menu {
              margin-top: 30px;
              padding-left: 2rem;
          }
          
          .footer-menu-name {
            //   display: none;
              color: #fffff2;
              font-size: 15px;
              font-weight: 900;
              letter-spacing: .1em;
              line-height: 18px;
              margin-bottom: 0;
              margin-top: 0;
              text-transform: uppercase;
          }
          .footer-menu-list {
              list-style: none;
              margin-bottom: 0;
              margin-top: 10px;
              padding-left: 0;
          }
          .footer-menu-list li {
              margin-top: 5px;
          }
          
          .footer-call-to-action-description {
              color: #fffff2;
              margin-top: 10px;
              margin-bottom: 20px;
          }
          .footer-call-to-action-button:hover {
              background-color: #fffff2;
              color: #00bef0;
              text-decoration: underline;
          }
          .button:last-of-type {
              margin-right: 0;
          }
          .footer-call-to-action-button {
              background-color: #01045b;
              border-radius: 21px;
              color: #fffff2;
              display: inline-block;
              font-size: 11px;
              font-weight: 900;
              letter-spacing: .1em;
              line-height: 18px;
              padding: 12px 30px;
              margin: 0 10px 10px 0;
              text-decoration: none;
              text-transform: uppercase;
              transition: background-color .2s;
              cursor: pointer;
              position: relative;
          }
          .footer-call-to-action {
              margin-top: 30px;
              .glow {
                box-shadow: 0 0 10px #01045b;
                border: 2px solid #01045b;
                color: #01045b;
                background-color: #fff;
                padding-inline: 12px;
                height: 6vh;
                font-family: "Alexandria", sans-serif;
                font-optical-sizing: auto;
                font-weight: 500;
                cursor: pointer;
              }
              
              .glow:hover {
                box-shadow: 0 0 20px #01045b;
                background-color: #01045b;
                color: #fff;
              }
          }
          .footer-call-to-action-title {
              color: #fffff2;
              font-size: 14px;
              font-weight: 900;
              letter-spacing: .1em;
              line-height: 18px;
              margin-bottom: 0;
              margin-top: 0;
              text-transform: uppercase;
          }
          .footer-call-to-action-link-wrapper {
              margin-bottom: 0;
              margin-top: 10px;
              color: #fff;
              text-decoration: none;
          }
          .footer-call-to-action-link-wrapper a {
              color: #fff;
              text-decoration: none;
          }
          .footer-social-links {
              bottom: 0;
              height: 54px;
              position: absolute;
              right: 0;
              width: 236px;
          }
          
          .footer-social-amoeba-svg {
              height: 54px;
              left: 0;
              display: block;
              position: absolute;
              top: 0;
              width: 236px;
          }
          
          .footer-social-amoeba-path {
              fill: #e1eeff;
          }
          
          .footer-social-link.linkedin {
              height: 26px;
              left: 3px;
              top: 11px;
              width: 26px;
          }
          
          .footer-social-link {
              display: block;
              padding: 10px;
              position: absolute;
          }
          
          .hidden-link-text {
              position: absolute;
              clip: rect(1px 1px 1px 1px);
              clip: rect(1px,1px,1px,1px);
              -webkit-clip-path: inset(0px 0px 99.9% 99.9%);
              clip-path: inset(0px 0px 99.9% 99.9%);
              overflow: hidden;
              height: 1px;
              width: 1px;
              padding: 0;
              border: 0;
              top: 50%;
          }
          
          .footer-social-icon-svg {
              display: block;
          }
          
          .footer-social-icon-path {
              fill: #4b4d81;
              transition: fill .2s;
          }
          
          .footer-social-link.twitter {
              height: 28px;
              left: 62px;
              top: 3px;
              width: 28px;
          }
          
        //   .footer-social-link.youtube {
        //       height: 24px;
        //       left: 123px;
        //       top: 12px;
        //       width: 24px;
        //   }
          
        //   .footer-social-link.github {
        //       height: 34px;
        //       left: 172px;
        //       top: 7px;
        //       width: 34px;
        //   }
          
          .footer-copyright {
            // background-color: #4b4d81;
            color: #fff;
            padding: 15px 30px;
            text-align: center;
          }
          
          .footer-copyright-wrapper {
              margin-left: auto;
              margin-right: auto;
              max-width: 1200px;
          }
          
          .footer-copyright-text {
            color: #fff;
              font-size: 13px;
              font-weight: 400;
              line-height: 18px;
              margin-bottom: 0;
              margin-top: 0;
          }
          
          .footer-copyright-link {
              color: #4b4d81 !important;
              text-decoration: none;
          }
          
          /* Media Query For different screens */
          @media (min-width:320px) and (max-width:479px)  { /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
            .footer-content {
              margin-left: auto;
              margin-right: auto;
              max-width: 1230px;
              padding: 40px 15px 160px;
              position: relative;
              display: flex;
              justify-content: space-between;
              & .footer-content-column {
                  & .footer-logo {
                      & .footer-logo-link {
                            & img {
                            width: 4rem;
                            height: 6rem;
                            margin-top: 6rem;
                            }
                      }
                  }
              }
            }
          }
          @media (min-width:480px) and (max-width:599px)  { /* smartphones, Android phones, landscape iPhone */
            .footer-content {
              margin-left: auto;
              margin-right: auto;
              max-width: 1230px;
              padding: 40px 15px 160px;
              position: relative;
              display: flex;
              justify-content: space-between;
              & .footer-content-column {
                & .footer-logo {
                    & .footer-logo-link {
                        & img {
                            width: 4rem;
                            height: 6rem;
                            margin-top: 6rem;
                        }
                    }
                }
              }
            }
          }
          @media (min-width:600px) and (max-width: 800px)  { /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
            .footer-content {
              margin-left: auto;
              margin-right: auto;
              max-width: 1230px;
              padding: 40px 15px 160px;
              position: relative;
              display: flex;
              justify-content: space-between;
              & .footer-content-column {
                & .footer-logo {
                    & .footer-logo-link {
                        & img {
                            width: 4rem;
                            height: 6rem;
                            margin-top: 6rem;
                        }
                    }
                }
              }
            }
          }
          @media (min-width:801px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */
          
          }
          @media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */
          
          }
          @media (min-width:1281px) { /* hi-res laptops and desktops */
          
          }
          
          @media (min-width: 760px) {
            .footer-content {
                margin-left: auto;
                margin-right: auto;
                max-width: 1230px;
                padding: 60px 15px 90px;
                position: relative;
                display: flex;
                justify-content: space-between;
                // & .footer-content-column {
                //     & .footer-logo {
                //         & .footer-logo-link {
                //             & img {
                //                 width: 4rem;
                //                 height: 6rem;
                //                 margin-top: 6rem;
                //             }
                //         }
                //     }
                // }
            }
          
            .footer-wave-svg {
                height: 50px;
            }
          
            .footer-content-column {
                width: 24.99%;
            }
          }
    }
    & .team-section {
        @media (min-width:320px) and (max-width:479px)  { /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
            .color-title {
                flex-direction: column;
                & .first, .second {
                    margin: 0 !important;
                    font-size: 24px !important;
                }
            }
            & p {
                font-size: 16px  !important;
            }
        }
        & p {
            font-family: "Alexandria",sans-serif;
            font-optical-sizing: auto;
            font-size: 24px;
            text-align: center  !important;
            line-height: 1.8rem  !important;
            font-weight: 300 !important;
        }
        & .color-title {
            display: flex;
            justify-content: center;
            h2 {
                font-family: "Alexandria", sans-serif;
                font-optical-sizing: auto;
                font-size: 36px;
                margin-bottom: 2rem;
            }
            & .first {
                color: #4b4d81;
                margin-right: 0.5rem;
            }
            & .second {
                color: #b98850;
            }
        }
        & .team-member4 {
            .card {
                max-width: 340px;
                margin: auto;
                overflow-y: auto;
                position: relative;
                z-index: 1;
                overflow-x: hidden;
                background-color: rgba(255, 255, 255, 1);
                display: flex;
                transition: 0.3s;
                flex-direction: column;
                border-radius: 10px;
                box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.2);
            }
              
              .card[data-state="#about"] {
                height: 450px;
                .card-main {
                  padding-top: 0;
                }
              }
              
              .card[data-state="#contact"] {
                height: 430px;
              }
              
              .card[data-state="#experience"] {
                height: 550px;
              }
              
              .card.is-active {
                .card-header {
                  height: 80px;
                }
              
                .card-cover {
                  height: 100px;
                  top: -50px;
                }
              
                .card-avatar {
                  transform: none;
                  left: 20px;
                  width: 50px;
                  height: 50px;
                  bottom: 10px;
                }
              
                .card-fullname,
                .card-jobtitle {
                  left: 86px;
                  transform: none;
                }
              
                .card-fullname {
                  bottom: 18px;
                  font-size: 19px;
                }
              
                .card-jobtitle {
                  bottom: 16px;
                  letter-spacing: 1px;
                  font-size: 10px;
                }
              }
              
              .card-header {
                position: relative;
                display: flex;
                height: 200px;
                flex-shrink: 0;
                width: 100%;
                transition: 0.3s;
              
                * {
                  transition: 0.3s;
                }
              }
              
              .card-cover {
                width: 100%;
                height: 100%;
                position: absolute;
                height: 160px;
                top: -20%;
                left: 0;
                will-change: top;
                background-size: cover;
                background-position: center;
                filter: blur(30px);
                transform: scale(1.2);
                transition: 0.5s;
              }
              
              .card-avatar {
                width: 100px;
                height: 100px;
                box-shadow: 0 8px 8px rgba(0, 0, 0, 0.2);
                border-radius: 50%;
                object-position: center;
                object-fit: cover;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%) translateY(-64px);
              }
              
              .card-fullname {
                position: absolute;
                bottom: 0;
                font-size: 22px;
                font-weight: 700;
                text-align: center;
                white-space: nowrap;
                transform: translateY(-10px) translateX(-50%);
                left: 50%;
              }
              
              .card-jobtitle {
                position: absolute;
                bottom: 0;
                font-size: 11px;
                white-space: nowrap;
                font-weight: 500;
                opacity: 0.7;
                text-transform: uppercase;
                letter-spacing: 1.5px;
                margin: 0;
                left: 50%;
                transform: translateX(-50%) translateY(-7px);
              }
              
              .card-main {
                position: relative;
                flex: 1;
                display: flex;
                padding-top: 10px;
                flex-direction: column;
              }
              
              .card-subtitle {
                font-weight: 700;
                font-size: 13px;
                margin-bottom: 8px;
              }
              
              .card-content {
                padding: 20px;
              }
              
              .card-desc {
                line-height: 1.6;
                color: #636b6f;
                font-size: 14px;
                margin: 0;
                font-weight: 400;
                font-family: "DM Sans", sans-serif;
              }
              
              .card-social {
                display: flex;
                align-items: center;
                padding: 0 20px;
                margin-bottom: 30px;
                svg {
                  fill: rgb(165, 181, 206);
                  width: 16px;
                  display: block;
                  transition: 0.3s;
                }
                a {
                  color: #8797a1;
                  height: 32px;
                  width: 32px;
                  border-radius: 50%;
                  display: inline-flex;
                  align-items: center;
                  justify-content: center;
                  transition: 0.3s;
                  background-color: rgba(93, 133, 193, 0.05);
                  border-radius: 50%;
                  margin-right: 10px;
              
                  &:hover {
                    svg {
                      fill: darken(rgb(165, 181, 206), 20%);
                    }
                  }
              
                  &:last-child {
                    margin-right: 0;
                  }
                }
              }
              
              .card-buttons {
                display: flex;
                background-color: #fff;
                margin-top: auto;
                position: sticky;
                bottom: 0;
                left: 0;
              
                button {
                  flex: 1 1 auto;
                  user-select: none;
                  background: 0;
                  font-size: 13px;
                  border: 0;
                  padding: 15px 5px;
                  cursor: pointer;
                  color: #5c5c6d;
                  transition: 0.3s;
                  font-family: "Jost", sans-serif;
                  font-weight: 500;
                  outline: 0;
                  border-bottom: 3px solid transparent;
              
                  &.is-active,
                  &:hover {
                    color: #2b2c48;
                    border-bottom: 3px solid #8a84ff;
                    background: linear-gradient(
                      to bottom,
                      rgba(127, 199, 231, 0) 0%,
                      rgba(207, 204, 255, 0.2) 44%,
                      rgba(211, 226, 255, 0.4) 100%
                    );
                  }
                }
              }
              
              .card-section {
                display: none;
                &.is-active {
                  display: block;
                  animation: fadeIn 0.6s both;
                }
              }
              
              @keyframes fadeIn {
                0% {
                  opacity: 0;
                  transform: translatey(40px);
                }
                100% {
                  opacity: 1;
                }
              }
              
              .card-timeline {
                margin-top: 30px;
                position: relative;
                &:after {
                  background: linear-gradient(
                    to top,
                    rgba(134, 214, 243, 0) 0%,
                    rgba(81, 106, 204, 1) 100%
                  );
                  content: "";
                  left: 42px;
                  width: 2px;
                  top: 0;
                  height: 100%;
                  position: absolute;
                  content: "";
                }
              }
              
              .card-item {
                position: relative;
                padding-left: 60px;
                padding-right: 20px;
                padding-bottom: 30px;
                z-index: 1;
                &:last-child {
                  padding-bottom: 5px;
                }
              
                &:after {
                  content: attr(data-year);
                  width: 10px;
                  position: absolute;
                  top: 0;
                  left: 37px;
                  width: 8px;
                  height: 8px;
                  line-height: 0.6;
                  border: 2px solid #fff;
                  font-size: 11px;
                  text-indent: -35px;
                  border-radius: 50%;
                  color: rgba(#868686, 0.7);
                  background: linear-gradient(
                    to bottom,
                    lighten(#516acc, 20%) 0%,
                    #516acc 100%
                  );
                }
              }
              
              .card-item-title {
                font-weight: 500;
                font-size: 14px;
                margin-bottom: 5px;
              }
              
              .card-item-desc {
                font-size: 13px;
                color: #6f6f7b;
                line-height: 1.5;
                font-family: "DM Sans", sans-serif;
              }
              
              .card-contact-wrapper {
                margin-top: 20px;
              }
              
              .card-contact {
                display: flex;
                align-items: center;
                font-size: 13px;
                color: #6f6f7b;
                font-family: "DM Sans", sans-serif;
                line-height: 1.6;
                cursor: pointer;
              
                & + & {
                  margin-top: 16px;
                }
              
                svg {
                  flex-shrink: 0;
                  width: 30px;
                  min-height: 34px;
                  margin-right: 12px;
                  transition: 0.3s;
                  padding-right: 12px;
                  border-right: 1px solid #dfe2ec;
                }
              }
              
              .contact-me {
                border: 0;
                outline: none;
                background: linear-gradient(
                  to right,
                  rgba(83, 200, 239, 0.8) 0%,
                  rgba(81, 106, 204, 0.8) 96%
                );
                box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
                color: #fff;
                padding: 12px 16px;
                width: 100%;
                border-radius: 5px;
                margin-top: 25px;
                cursor: pointer;
                font-size: 14px;
                font-weight: 500;
                font-family: "Jost", sans-serif;
                transition: 0.3s;
            }
        }
        & .team-member3 {
            .container {
                height: 500px;
                width: 350px;
                box-shadow: 0 10px 20px black;
                background-size: cover;
                font-family: 'Abel', sans-serif;
                background-image: url("https://xp.io/storage/15pewlWA.jpg");
            }
            
            .gradient {
                height: 100%;
                width: 100%;
                top: 0;
                background: linear-gradient(to top, rgba(28, 171, 238, 0.7), rgba(253, 252, 252, 0.21));
                color: white;
            }
            
            .content {
                bottom: 0;
                position: absolute;
                margin-bottom: 20px;
                padding: 20px;
            }
            
            h2 {
                font-size: xx-large;
                letter-spacing: -1px;
            }
            
            .details {
                font-style: italic;
                color: rgb(185, 206, 224);
            }
            
            .icons {
                display: flex;
                flex-direction: column;
                top: -30px;
                position: absolute;
                left: 85%;
            }
            
            .icons i {
                font-size: 25px;
                margin-bottom: 20px;
                color: rgb(202, 215, 226);
                transition: 0.3s;
            }
            
            .icons i:hover {
                color: white;
            }
        }
        & h2 {
            font-family: "Alexandria", sans-serif;
            font-optical-sizing: auto;
            font-weight: 700;
            font-size: 24px;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 3rem;
        }
        & p {
            font-family: "Alexandria", sans-serif;
            font-optical-sizing: auto;
            font-weight:400;
            font-size: 24px;
            line-height: 2.8rem;
            // letter-spacing: 3px;
            font-weight: bold;
            text-align: center;
        }
        @media (min-width:320px) and (max-width:479px)  { /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
            & .team-members-list {
                .member-list-1 {
                    flex-direction: column;
                    .teamcontainer {
                        padding: 0 !important;
                    }
                }
            }
        }
        & .team-members-list {
            display: flex;
            flex-direction: column;
            & .member-list-1 {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                justify-content: center;
                background: #4b4d81;
                padding-block: 1.5rem;
            }
        }
    }
    & .section3-container {
        padding-bottom: 1rem;
    }
    & .section1-container, .section2-container, .section3-container  {
        & .section1-part1, .section2-part1, .section3-part1 {
            @media (min-width:320px) and (max-width:479px)  { /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
                .color-title {
                    flex-direction: column;
                    margin-top: 1.5rem;
                    & .first, .second {
                        margin: 0 !important;
                        display: flex;
                        justify-content: center;
                        font-size: 24px !important;
                    }
                }
                & p {
                    width: 92% !important;
                    text-align: justify !important;
                    font-size: 16px !important;
                }
            }
            display: flex;
            flex-direction: column;
            align-items: center;
            & .sub-section1 {
                display: flex;
                align-items: center;
                margin-top: 1.5rem;
                padding: 2rem;
                justify-content: space-evenly;
                width: 95%;
                background: rgb(75, 77, 129);
                & img {
                    width: 90%;
                }
                & p {
                    width: 35%;
                }
            }
            @media (min-width:320px) and (max-width:479px)  { /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
                & .sub-section1 {
                    padding: 0;
                }
            }
            & .color-title {
                display: flex;
                justify-content: center;
                h2 {
                    font-family: "Alexandria", sans-serif;
                    font-optical-sizing: auto;
                    font-size: 36px;
                    margin-bottom: 0;
                }
                & .first {
                    color: #4b4d81;
                    margin-right: 0.5rem;
                }
                & .second {
                    color: #b98850;
                }
            }
            & p {
                width: 68%;
                font-family: "Alexandria", sans-serif;
                font-optical-sizing: auto;
                font-weight:300;
                font-size: 24px;
                text-align: center;
                line-height: 1.8rem;
                // letter-spacing: 1.4px;
            }
            & img {
                width: 75%;
            }
        }
        & .section1-part2 {
            display: flex;
            padding: 3rem;
            padding-inline: 5rem;
            justify-content: space-between;
            & .sub-section1, .sub-section2, .sub-section3 {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                & img {
                    width: 30%;
                }
                & .ipo {
                    width: 24%;
                }
                & h3 {
                    font-family: "Alexandria", sans-serif;
                    font-optical-sizing: auto;
                    font-weight: 500;
                    font-size: 16px;
                    letter-spacing:1px;
                }
                & p {
                    font-family: "Alexandria", sans-serif;
                    font-optical-sizing: auto;
                    font-weight:400;
                    font-size: 14px;
                    text-align: center;
                    line-height: 1.8rem;
                    letter-spacing: 1.5px;
                    width: 86%;
                    text-align: justify;
                }
            }
        }
        & .section2-part2 {
            @media (min-width:320px) and (max-width:479px)  { /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
                padding: 0 !important;
                flex-direction: column;
                & .section2-image {
                    width: unset !important;
                }
                & .section2-bulletin {
                    width: unset !important;
                    & .bullet-points {
                        & .icon-title {
                            width: unset !important;
                            align-items: center;
                            & p {   
                                text-align: left !important;
                                font-size: 20px !important;      
                            }
                        }
                        & p {
                            font-size: 16px!important;
                        }
                    }
                }
            }
            display: flex;
            padding: 2rem;
            & .section2-image {
                display: flex;
                width: 50%;
                align-items: center;
                justify-content: center;
                & img {
                    width: 100%;
                }
            }
            & .section2-bulletin {
                width: 50%;
                background: #4b4d81;
                & .bullet-points {
                    display: flex;
                    flex-direction: column;
                    margin: 2rem;
                    margin-bottom: 1rem;
                    & .icon-title {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        width: 93%;
                        & img {
                            width: 7%;
                        }
                        & p {
                            font-family: "Alexandria", sans-serif;
                            font-optical-sizing: auto;
                            font-weight: 600;
                            font-size: 28px;
                            text-align: justify;
                            letter-spacing: 1.2px;
                            width: 90%;
                            margin: 0;
                            color: #daa560;
                        }
                    }
                    & p {
                            font-family: "Alexandria", sans-serif;
                            font-optical-sizing: auto;
                            font-weight: 300;
                            font-size: 22px;
                            text-align: justify;
                            // letter-spacing: 1.4px;
                            // width: 90%;
                            color: white;
                        }
                    }
                }
            }
        }
    }
`;

export const BannerContainer = styled.div`
    @media (min-width:320px) and (max-width:479px)  { /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
        & .image-desktop {
            display: none !important;
        }
        & .image-mobile {
            display: block !important;
        }
    }
    @media (min-width:480px) and (max-width:599px)  { /* smartphones, Android phones, landscape iPhone */
        & .image-desktop {
            display: none;
        }
        & .image-mobile {
            display: block;
        }
    }
    @media (min-width:600px) and (max-width: 800px)  {
        & .image-desktop {
            display: none;
        }
        & .image-mobile {
            display: block;
        }
    } /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
    & .image-desktop {
        display: block;
    }
    & .image-mobile {
        display: none;
    }
    & img {
        width: 100%;
    }
`;

export const DividerContainer = styled.div`
    margin:100px auto;
    margin-top: 5rem;
    margin-bottom: 3rem;
    width:400px; 
    max-width: 100%;
    span {
        width:50px;
        height:50px; 
        position:absolute; 
        bottom:100%;
        margin-bottom:-25px;
        left:50%;
        margin-left:-25px;
        border-radius:100%;
        box-shadow:0 2px 4px #bfcddb;
        background:#fff;
    }
    i {
        position:absolute;
        top:4px; bottom:4px;
        left:4px; right:4px;
        border-radius:100%;
        border:1px dashed #bfcddb;
        text-align:center;
        line-height:40px;
        font-style:normal;
        color:#bfcddb;
    }
    position:relative;
    & .astrodividermask { 
        overflow:hidden; height:20px; 
    }
    
    & .astrodividermask:after { 
        content:''; 
        display:block; margin:-25px auto 0;
        width:100%;
        height:25px;  
        border-radius:125px / 12px;
        box-shadow:0 0 8px #bfcddb;
    }
`;

export const PopOverContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px;
    visibility: visible;
    transform: translate(0, -20px);
    width: 500px;
    // height: auto;
    overflow-y:scroll;
    transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
    margin-top: 1rem;
    & .nameinfo {
        & h4 {
            font-family: "Alexandria", sans-serif;
            font-optical-sizing: auto;
            font-weight: 700;
            font-size: 22px;
            letter-spacing:1px;
            text-align: center;
        }
    }
    & .background {
        display: flex;
        flex-direction: column;
        align-items: center;
        & h3 {
            font-family: "Alexandria", sans-serif;
            font-optical-sizing: auto;
            font-weight: 700;
            font-size: 19px;
            letter-spacing:1px;
            color: #b98950;
        }
        & .background-list {
            background: #4b4d81;
            margin: 0;
            padding-inline: 2rem;
            & ul {
                padding: 0;
                & li {
                    font-family: "Alexandria", sans-serif;
                    font-optical-sizing: auto;
                    font-weight:400;
                    font-size: 14px;
                    line-height: 1.3rem;
                    padding-bottom: 1rem;
                    letter-spacing: 1px;
                    text-align: justify;
                    color: white;
                }
            }
        }
    }
    & .indus-exp {
        display: flex;
        flex-direction: column;
        & h3 {
            font-family: "Alexandria", sans-serif;
            font-optical-sizing: auto;
            font-weight: 700;
            font-size: 18px;
            letter-spacing:1px;
        }
        & ul {
            & li {
                font-family: "Alexandria", sans-serif;
                font-optical-sizing: auto;
                font-weight:400;
                font-size: 12px;
                line-height: 1rem;
                margin-bottom: 0.5rem;
                letter-spacing: 1px;
            }
        }
        & .experience {
            display: flex;
            & .present, .past {
                display: flex;
                flex-direction: column;
                align-items: center;
                & h4 {
                    font-family: "Alexandria", sans-serif;
                    font-optical-sizing: auto;
                    font-weight: 700;
                    font-size: 14px;
                    letter-spacing:1px;
                }
                & ul {
                    margin: 0;
                    & li {
                        font-size: 11px;
                    }
                }
            }
        }
    }
`;

export const TeamMemberContainer = styled(Container)`
    @media (min-width:320px) and (max-width:479px)  { /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
        margin-left: auto;
        margin-right: auto;
        max-width: 1230px;
        padding: 40px 15px 160px;
        position: relative;
        .team-thumb-bg {
            width: unset !important;
        }
    }
    cursor: pointer;
    display: flex !important;
    flex-direction: column;
    padding: 0 5px;
    vertical-align: top;
    max-width: 30% !important;
    align-items: center;
    flex: 1 0 30%;
    margin-block: 2rem !important;
    margin-inline: 0 !important;
    & .team-thumb-bg {
        background: url(https://www.saltedstone.com/hubfs/2018/about/portraitBG.svg) top no-repeat;
        padding: 15px;
        position: relative;
        transition: .2s;
        width: 47%;
        display: flex;
        align-items: center;
        justify-content: center;
        & .team-thumb {
            border-radius: 50%;
            // margin-bottom: 25px;
            overflow: hidden;
            position: relative;
            z-index: 1;
            & img {
                height: auto;
                max-width: 100%;
            }
        }
        & .square, .inner {
            a {
                text-decoration: none;
            }
            bottom: 0;
            display: table-cell;
            left: 0;
            right: 0;
            text-align: center;
            top: 0;
            vertical-align: middle;
            & .dark {
                & .lettered-avatar {
                    font-family: "Alexandria", sans-serif;
                    font-optical-sizing: auto;
                    font-weight: 800;
                    letter-spacing: .11em;
                }
            }
        }
        & .square {
            box-sizing: content-box;
        }
    }
    & .team-thumb-bg:before {
        background: url(https://www.saltedstone.com/hubfs/2018/about/usa.svg) 50% no-repeat;
        background-size: 100% auto;
    }
    & .team-info {
        @media (min-width:320px) and (max-width:479px)  { /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
            padding-top: 8px;
            & h3 {
                font-size: 18px !important;
            }
            & span {
                font-size: 10px !important;
            }
        }
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        & .name {
            font-size: 24px;
            color: #bfcddb;
            margin-bottom: 7px;
            font-family: "Alexandria", sans-serif;
            font-optical-sizing: auto;
            font-weight: 800;
            letter-spacing: .11em;
            line-height: 1.3em;
            text-transform: uppercase;
            text-align: center;
            cursor: pointer;
        }
        & .job {
            font-size: 14px;
            display: block;
            padding: 0 10px;
            letter-spacing: .11em;
            line-height: 1.3em;
            text-transform: uppercase;
            font-family: "Alexandria", sans-serif;
            font-weight:400;
            font-optical-sizing: auto;
            font-weight: bolder;
            color: #b98850;
            text-align: center;
        }
    }
`;