import styled from "styled-components";

export const HeaderContainer = styled.div`
    @media (min-width:320px) and (max-width:479px)  {
      background: #d0e9f8 !important;
      justify-content: flex-start;
      & .logo {
        padding-right: 16px;
        & img {
          width: 10rem !important;
        }
      }
      & .header-section2 {
        width: 50% !important;
        & .social-share {
          & svg {
            height: 1em !important;
            width: 2rem !important;
          }
        }
        & .contact-us {
          & .glow {
            text-wrap: nowrap;
            font-size: 10px !important;
          }
        }
      }
    }
    display: flex;
    height: 5rem;
    width: auto;
    box-shadow: 0px 0px 0.5px;
    justify-content: space-between;
    padding-block: 0.5rem;
    background: linear-gradient(
      to right,
      #ffffff,
      #d0e9f8,
      #e1f4ff
    );
    background-size: 200% 100%; /* Double the width to accommodate the animation */
    animation: animateGradient 5s linear infinite;
    @keyframes animateGradient {
        0% {
          background-position: 0 0%; /* Start from left */
        }
        50% {
          background-position: 100% 50%; /* Move to right */
        }
        100% {
          background-position: 0 100%; /* Move back to left */
        }
    
    }
    & .logo {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        & a {
          & img {
              width: 16rem;
          }
        }
    }
    & .header-section2 {
        display: flex;
        width: 25%;
        align-items: center;
        justify-content: space-around;
        & .social-share {
            display: flex;
            width: 30%;
            justify-content: space-evenly;
            & svg {
                width: 3rem;
                height: 2rem;
                cursor: pointer;
                fill: #4b4d81;
            }
        }
        & .contact-us {
            & button {
                display: inline-block;
                padding: 10px 20px;
                margin: 10px;
                text-align: center;
                text-decoration: none;
                font-size: 16px;
                cursor: pointer;
                transition: background-color 0.3s, color 0.3s;
                font-family: "Alexandria", sans-serif;
                font-optical-sizing: auto;
                font-weight: 500;
            }
            .glow {
              box-shadow: 0 0 10px #01045b;
              border: 2px solid #01045b;
              color: #01045b;
              background-color: #fff;
            }
            
            .glow:hover {
              box-shadow: 0 0 20px #01045b;
              background-color: #01045b;
              color: #fff;
            }
        }
    }
`;