import { Typography } from '@mui/material'
import React from 'react'
import { PopOverContainer } from './styles'

function PopoverArea({ background, indusExp, name }) {
    return (
        <PopOverContainer>
            <div className="nameinfo">
                <Typography
                    variant="h4"
                >
                    { name }
                </Typography>
            </div>
            <div className="background">
                <h3>Background</h3>
                <div className="background-list">
                    <ul>
                        {
                            background.map((item) => <li>{item}</li>)
                        }
                    </ul>
                </div>
            </div>
            {/* <div className="indus-exp">
                <h3>Industry Experience</h3>
                <div className="experience">
                    {
                        indusExp.present && (
                            <div className="present">
                                <h4>Present</h4>
                                <ul>
                                {
                                    indusExp.present.map((item) => <li>{item}</li>)
                                }
                                </ul>
                            </div>
                        )
                    }
                    {
                        indusExp.past && (
                            <div className="past">
                                <h4>Past</h4>
                                <ul>
                                {
                                    indusExp.past.map((item) => <li>{item}</li>)
                                }
                                </ul>
                            </div>
                        )
                    }
                </div>
            </div> */}
        </PopOverContainer>
    )
}

export default PopoverArea
